@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

$media-path: "../../assets/media/";
$font-path: "../../assets/fonts/";

$header-height: 7rem;
$footer-height: 4.8rem;

$app-color: #00b4d5;
$app-easing: cubic-bezier(0.35, 0.01, 0.77, 0.34);
$yellow: #dc3545;

*,
::before,
::after {
    border-width: 0;
    border-style: none;
    border-color: theme("borderColor.default", currentColor);
}
