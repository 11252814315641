.no-foto {
  background-image: url("#{$media-path}images/kuser.png") !important;
  background: whitesmoke;
  width: 3.4rem;
  height: 50px;
  background-size: contain;
  border-radius: 10px;
}

.content-center {
  display: flex;
  justify-content: center;
}

table {
  border-collapse: collapse;
  width: 100%;

  &.border {
    &.thick {
      border: 2px solid #000;
      font-size: 12px;
    }

    th {
      text-transform: uppercase;
      padding: 2px;
      border: 1px solid #000 !important;
      background: #eee;
      text-align: inherit;
      color: rgba(0, 0, 0, 0.87);
      vertical-align: inherit;
      font-style: none;
      font-weight: bold;
      text-transform: none;
    }

    td {
      padding: 2px 5px;
      border: 1px solid #aaa !important;
      vertical-align: inherit;
    }
  }

  &.border tr {
    border-bottom: 1px solid #aaa;
  }

  tr.thick,
  thead tr.thick {
    border-bottom: 2px solid #000 !important;
  }
}

table {

  &.borders {
    &.thick {
      border: 2px solid #000;
      font-size: 12px;
    }

    th {
      text-transform: uppercase;
      padding: 2px;
      border: 1px solid #000 !important;
      background: #eee;
      text-align: inherit;
      color: rgba(0, 0, 0, 0.87);
      vertical-align: inherit;
      font-style: none;
      font-weight: bold;
      text-transform: none;
    }

    td {
      padding: 2px 5px;
      border-right: 1px solid #aaa;
      vertical-align: inherit;
    }

    td.l:first-child {
      border-left: 1px solid #aaa !important;
    }

    td:last-child {
      border-right: unset;
    }

    tr.l:last-child {
      border-bottom: 1px solid #aaa !important;
    }
  }

  &.borders tr {
    // border-bottom: 1px solid #aaa;
  }

  tr.thick,
  thead tr.thick {
    border-bottom: 2px solid #000 !important;
  }
}

#body {
  h3 {
    font-size: 18px;
  }

  .header {
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin: -5px auto;
  }
}

.react-bs-container-body {
  overflow-y: visible !important;
  overflow: unset !important;
}

.profile-user-img {
  margin: 0 auto;
  width: 150px;
  padding: 3px;
  border: 3px solid #d2d6de;
}

.img-circle {
  border-radius: 10%;
}

.subtitle_head {
  padding: 10px 50px 10px 5px;
  background-color: #a2f2ef;
  margin: 15px 0px 10px 0px;
  text-align: left;
  color: #111;
}

.table-bordered.fs-11 {
  font-size: 11px;
}


table.bp3-html-table.fs-11 tbody tr:first-child td {
  box-shadow: none;
}