.gd-light-blue-teal {
  background: #b3e5fc;
  background: -webkit-linear-gradient(45deg, #b3e5fc 0%, #64ffda 100%);
  background: linear-gradient(45deg, #b3e5fc 0%, #64ffda 100%);
}

.gd-light-blue-teal.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(100, 255, 218, 0.5);
}

.gd-indigo-light-blue {
  background: #c5cae9;
  background: -webkit-linear-gradient(45deg, #c5cae9 0%, #b3e5fc 100%);
  background: linear-gradient(45deg, #c5cae9 0%, #b3e5fc 100%);
}

.gd-indigo-light-blue.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(179, 229, 252, 0.5);
}

.gd-light-blue-indigo {
  background: #b3e5fc;
  background: -webkit-linear-gradient(45deg, #b3e5fc 0%, #9fa8da 100%);
  background: linear-gradient(45deg, #b3e5fc 0%, #9fa8da 100%);
}

.gd-light-blue-indigo.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(159, 168, 218, 0.5);
}

.gd-yellow-green {
  background: #ffff8d;
  background: -webkit-linear-gradient(45deg, #ffff8d 0%, #b9f6ca 100%);
  background: linear-gradient(45deg, #ffff8d 0%, #b9f6ca 100%);
}

.gd-yellow-green.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(185, 246, 202, 0.5);
}

.gd-orange-deep-orange {
  background: #ffe0b2;
  background: -webkit-linear-gradient(45deg, #ffe0b2 0%, #ffccbc 100%);
  background: linear-gradient(45deg, #ffe0b2 0%, #ffccbc 100%);
}

.gd-orange-deep-orange.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(255, 204, 188, 0.5);
}

.gd-deep-purple-purple {
  background: #d1c4e9;
  background: -webkit-linear-gradient(45deg, #d1c4e9 0%, #f3e5f5 100%);
  background: linear-gradient(45deg, #d1c4e9 0%, #f3e5f5 100%);
}

.gd-deep-purple-purple.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(243, 229, 245, 0.5);
}

.gd-red-pink {
  background: #ff5252;
  background: -webkit-linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
  background: linear-gradient(45deg, #ff5252 0%, #f48fb1 100%);
}

.gd-red-pink.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(244, 143, 177, 0.5);
}

.gd-light-green-amber {
  background: #c5e1a5;
  background: -webkit-linear-gradient(45deg, #c5e1a5 0%, #fff8e1 100%);
  background: linear-gradient(45deg, #c5e1a5 0%, #fff8e1 100%);
}

.gd-light-green-amber.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(255, 248, 225, 0.5);
}

.gd-amber-amber {
  background: #ff6f00;
  background: -webkit-linear-gradient(45deg, #ff6f00 0%, #ffca28 100%);
  background: linear-gradient(45deg, #ff6f00 0%, #ffca28 100%);
}

.gd-amber-amber.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(255, 111, 0, 0.3);
}

.gd-purple-pink {
  background: #ea80fc;
  background: -webkit-linear-gradient(45deg, #ea80fc 0%, #fce4ec 100%);
  background: linear-gradient(45deg, #ea80fc 0%, #fce4ec 100%);
}

.gd-purple-pink.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(252, 228, 236, 0.5);
}

.gd-indigo-light-blue {
  background: #3949ab;
  background: -webkit-linear-gradient(45deg, #3949ab 0%, #4fc3f7 100%);
  background: linear-gradient(45deg, #3949ab 0%, #4fc3f7 100%);
}

.gd-indigo-light-blue.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(79, 195, 247, 0.5);
}

.gd-teal-cyan {
  background: #e0f2f1;
  background: -webkit-linear-gradient(45deg, #e0f2f1 0%, #00e5ff 100%);
  background: linear-gradient(45deg, #e0f2f1 0%, #00e5ff 100%);
}

.gd-teal-cyan.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(0, 229, 255, 0.5);
}

.gd-blue-grey-blue-grey {
  background: #cfd8dc;
  background: -webkit-linear-gradient(45deg, #cfd8dc 0%, #546e7a 100%);
  background: linear-gradient(45deg, #cfd8dc 0%, #546e7a 100%);
}

.gd-blue-grey-blue-grey.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(84, 110, 122, 0.5);
}

.gd-cyan-light-green {
  background: #0097a7;
  background: -webkit-linear-gradient(45deg, #0097a7 0%, #b2ff59 100%);
  background: linear-gradient(45deg, #0097a7 0%, #b2ff59 100%);
}

.gd-cyan-light-green.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(178, 255, 89, 0.5);
}

.gd-orange-amber {
  background: #e65100;
  background: -webkit-linear-gradient(45deg, #e65100 0%, #ffa000 100%);
  background: linear-gradient(45deg, #e65100 0%, #ffa000 100%);
}

.gd-orange-amber.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(25, 118, 210, 0.5);
}

.gd-indigo-blue {
  background: #303f9f;
  background: -webkit-linear-gradient(45deg, #303f9f 0%, #1976d2 100%);
  background: linear-gradient(45deg, #303f9f 0%, #1976d2 100%);
}

.gd-indigo-blue.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(25, 118, 210, 0.5);
}

.gd-brown-brown {
  background: #6d4c41;
  background: -webkit-linear-gradient(45deg, #6d4c41 0%, #d7ccc8 100%);
  background: linear-gradient(45deg, #6d4c41 0%, #d7ccc8 100%);
}

.gd-brown-brown.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(187, 222, 251, 0.5);
}

.gd-blue-grey-blue {
  background: #263238;
  background: -webkit-linear-gradient(45deg, #263238 0%, #2979ff 100%);
  background: linear-gradient(45deg, #263238 0%, #2979ff 100%);
}

.gd-blue-grey-blue.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(41, 121, 255, 0.5);
}

.gd-purple-deep-orange {
  background: #8e24aa;
  background: -webkit-linear-gradient(45deg, #8e24aa 0%, #ff6e40 100%);
  background: linear-gradient(45deg, #8e24aa 0%, #ff6e40 100%);
}

.gd-purple-deep-orange.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(255, 110, 64, 0.5);
}

.gd-green-teal {
  background: #00d1b2;

  background: -webkit-linear-gradient(45deg, #00d1b2 0%, #0a906e 100%);
  background: linear-gradient(45deg, #0a906e 0%, #00d1b2 100%);
}

.gd-green-teal.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(77, 182, 172, 0.5);
}

.gd-purple-light-blue {
  background: #e040fb;
  background: -webkit-linear-gradient(45deg, #e040fb 0%, #4fc3f7 100%);
  background: linear-gradient(45deg, #e040fb 0%, #4fc3f7 100%);
}

.gd-purple-light-blue.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(79, 195, 247, 0.5);
}

.gd-cyan-cyan {
  background: #18ffff;
  background: -webkit-linear-gradient(45deg, #18ffff 0%, #00e5ff 100%);
  background: linear-gradient(45deg, #18ffff 0%, #00e5ff 100%);
}

.gd-cyan-cyan.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(0, 229, 255, 0.5);
}

.gd-yellow-teal {
  background: #fff9c4;
  background: -webkit-linear-gradient(45deg, #fff9c4 0%, #64ffda 100%);
  background: linear-gradient(45deg, #fff9c4 0%, #64ffda 100%);
}

.gd-yellow-teal.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(100, 255, 218, 0.5);
}

.gd-purple-deep-purple {
  background: #7b1fa2;
  background: -webkit-linear-gradient(45deg, #7b1fa2 0%, #7c4dff 100%);
  background: linear-gradient(45deg, #7b1fa2 0%, #7c4dff 100%);
}

.gd-purple-deep-purple.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(124, 77, 255, 0.5);
}

.gd-cyan-light-green {
  background: #00e5ff;
  background: -webkit-linear-gradient(45deg, #00e5ff 0%, #ccff90 100%);
  background: linear-gradient(45deg, #00e5ff 0%, #ccff90 100%);
}

.gd-cyan-light-green.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(204, 255, 144, 0.5);
}

.gd-purple-amber {
  background: #d500f9;
  background: -webkit-linear-gradient(45deg, #d500f9 0%, #ffa000 100%);
  background: linear-gradient(45deg, #d500f9 0%, #ffa000 100%);
}

.gd-purple-amber.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(255, 160, 0, 0.5);
}

.gd-indigo-purple {
  background: #303f9f;
  background: -webkit-linear-gradient(45deg, #303f9f 0%, #7b1fa2 100%);
  background: linear-gradient(45deg, #303f9f 0%, #7b1fa2 100%);
}

.gd-indigo-purple.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(123, 31, 162, 0.5);
}

.gd-deep-purple-blue {
  background: #6200ea;
  background: -webkit-linear-gradient(45deg, #6200ea 0%, #1976d2 100%);
  background: linear-gradient(45deg, #6200ea 0%, #1976d2 100%);
}

.gd-deep-purple-blue.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(25, 118, 210, 0.5);
}

.gd-deep-orange-orange {
  background: #bf360c;
  background: -webkit-linear-gradient(45deg, #bf360c 0%, #f57c00 100%);
  background: linear-gradient(45deg, #bf360c 0%, #f57c00 100%);
}

.gd-deep-orange-orange.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(245, 124, 0, 0.5);
}

.gd-light-blue-cyan {
  background: #0288d1;
  background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
  background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
}

.gd-light-blue-cyan.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(38, 198, 218, 0.5);
}

.gd-blue-indigo {
  background: #2962ff;
  background: -webkit-linear-gradient(45deg, #2962ff 0%, #3949ab 100%);
  background: linear-gradient(45deg, #2962ff 0%, #3949ab 100%);
}

.gd-blue-indigo.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(57, 73, 171, 0.5);
}

.gd-semi-dark {
  background: #00aeff;
  background: -webkit-linear-gradient(180deg, #00aeff 0%, #3369e6 100%);
  background: linear-gradient(-90deg, #00aeff 0%, #3369e6 100%);
}

.gd-semi-dark.gradient-shadow {
  box-shadow: 0 6px 20px 0 rgba(51, 105, 230, 0.5);
}

.text-red {
  color: #dd4b39 !important;
}

.text-yellow {
  color: #f39c12 !important;
}

.text-aqua {
  color: #00c0ef !important;
}

.text-blue {
  color: #0073b7 !important;
}

.text-black {
  color: #111 !important;
}

.text-light-blue {
  color: #3c8dbc !important;
}

.text-green {
  color: #00a65a !important;
}

.text-gray {
  color: #d2d6de !important;
}

.text-navy {
  color: #001f3f !important;
}

.text-teal {
  color: #39cccc !important;
}

.text-olive {
  color: #3d9970 !important;
}

.text-lime {
  color: #01ff70 !important;
}

.text-orange {
  color: #ff851b !important;
}

.text-fuchsia {
  color: #f012be !important;
}

.text-purple {
  color: #605ca8 !important;
}

.text-maroon {
  color: #d81b60 !important;
}