
.autocomplete {
    position: relative;
  }
  .autocomplete .dropdown-menu {
    display: block;
    min-width: 100%;
  }
  .autocomplete .dropdown-menu.is-opened-top {
    top: auto;
    bottom: 100%;
  }
  .autocomplete .dropdown-content {
    overflow: auto;
    max-height: 200px;
  }
  .autocomplete .dropdown-item.is-hovered,
  .autocomplete .dropdown .dropdown-menu .has-link a.is-hovered,
  .dropdown .dropdown-menu .has-link .autocomplete a.is-hovered {
    background: #f5f5f5;
    color: #0a0a0a;
  }
  .autocomplete .dropdown-item.is-disabled,
  .autocomplete .dropdown .dropdown-menu .has-link a.is-disabled,
  .dropdown .dropdown-menu .has-link .autocomplete a.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .autocomplete.is-small {
    border-radius: 2px;
    font-size: 0.75rem;
  }
  .autocomplete.is-medium {
    font-size: 1.25rem;
  }
  .autocomplete.is-large {
    font-size: 1.5rem;
  }

.highlight {
    font-weight: 700 !important;
}

button.left {
  padding-right: 0px;
  padding-left: 2px;
}

.is-info.right {
  display: inline-flex;
  padding: 5px;
  margin-left: 100px;
  font-size: 14px;
}
.number-change {
  display: inline-flex;
  flex-direction: column;
  /* background: #0a0a0a; */
  width: 30px;
  align-items: center;
  position: absolute;
  right: 0;
}
.number-change:hover {
  background: rgba(75, 39, 39, 0.3);
}
.number-change .tombol {
  cursor: pointer;
  stroke: #007bff;
}
.number-change .tombol:hover {
  /* stroke: red; */
  stroke-width: 4px;
}
/* .is-tooltip-right input {

} */
input.is-tooltip-right[disabled] {
  background-color: rgba(138, 72, 72, 0.3);
  border-color: rgba(0,0,0,0.5);
  box-shadow: none;
  color: white;
}