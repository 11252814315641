 #loader-wrapper {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1000;
 }

 #loader {
   display: block;
   position: relative;
   left: 50%;
   top: 50%;
   width: 200px;
   height: 150px;
 }