.box.buttons {
    cursor: pointer;
}

.buttons:active {
    /* cursor: pointer;
     */
    background-color: rebeccapurple;
}
.buttons:focus {
    /* cursor: pointer;
     */
    background-color: rebeccapurple;
}
.buttons:hover {
    /* cursor: pointer;
     */
    background-color: whitesmoke;
    /* color: white; */
    transform: scale(1.1);
}