@media screen and (max-width: 769px), print {
  .card {
    overflow-y: auto;
    max-height: 46rem;
    overflow-x: hidden;
  }
}
.modal {
  z-index: 120;
}
@media screen and (min-width: 769px), print {
  .field-label {
    flex-grow: 2.2;
    margin-right: 0.6rem;
    text-align: right;
  }
}

form .field .control button {
  text-decoration: none;
  width: 120px;
}
.tag {
  // box-shadow: 2px 1px 3px rgba(0,0,0,0.4);
}

.bp3-overlay {
  z-index: 100;
}

@-webkit-keyframes color_change {
  0% {
    background-color: white;
  }
  50% {
    background-color: green;
  }
  70% {
    background-color: rgb(164, 245, 164);
  }
  100% {
    background-color: white;
  }
}
@-moz-keyframes color_change {
  0% {
    background-color: white;
  }
  50% {
    background-color: green;
  }
  75% {
    background-color: rgb(164, 245, 164);
  }
  100% {
    background-color: white;
  }
}

@keyframes color_change {
  0% {
    background-color: white;
  }
  50% {
    background-color: green;
  }
  75% {
    background-color: rgb(164, 245, 164);
  }
  100% {
    background-color: white;
  }
}

.has-anim {
  animation-name: color_change;
  animation-duration: 5s;
}

@-webkit-keyframes color_change_error {
  0% {
    background-color: white;
  }
  50% {
    background-color: rgb(128, 0, 21);
  }
  70% {
    background-color: rgb(164, 245, 164);
  }
  100% {
    background-color: white;
  }
}
@-moz-keyframes color_change_error {
  0% {
    background-color: white;
  }
  50% {
    background-color: rgb(128, 0, 21);
  }
  75% {
    background-color: rgb(164, 245, 164);
  }
  100% {
    background-color: white;
  }
}

@keyframes color_change_error {
  0% {
    background-color: white;
  }
  50% {
    background-color: rgb(128, 0, 21);
  }
  75% {
    background-color: rgb(164, 245, 164);
  }
  100% {
    background-color: white;
  }
}

.has-anim-error {
  animation-name: color_change_error;
  animation-duration: 5s;
}

.bp3-transition-container {
  z-index: 200 !important;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}
