.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .small-box > .inner {
    padding: 10px;
  }
  .small-box > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
  .small-box > .small-box-footer:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.15);
  }
  .small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
  }
  .small-box p {
    font-size: 15px;
  }
  .small-box p > small {
    display: block;
    color: #f9f9f9;
    font-size: 13px;
    margin-top: 5px;
  }
  .small-box h3,
  .small-box p {
    z-index: 5;
  }
  .small-box .icons {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0, 0, 0, 0.15);
  }
  .small-box:hover {
    text-decoration: none;
    color: #f9f9f9;
  }
  .small-box:hover .icons {
    font-size: 95px;
  }
  @media (max-width: 767px) {
    .small-box {
      text-align: center;
    }
    .small-box .icons {
      display: none;
    }
    .small-box p {
      font-size: 12px;
    }
  }
  .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .box.box-primary {
    border-top-color: #3c8dbc;
  }
  .box.box-info {
    border-top-color: #00c0ef;
  }
  .box.box-danger {
    border-top-color: #dd4b39;
  }
  .box.box-warning {
    border-top-color: #f39c12;
  }
  .box.box-success {
    border-top-color: #00a65a;
  }
  .box.box-default {
    border-top-color: #d2d6de;
  }
  .box.collapsed-box .box-body,
  .box.collapsed-box .box-footer {
    display: none;
  }
  .box .nav-stacked > li {
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
  }
  .box .nav-stacked > li:last-of-type {
    border-bottom: none;
  }
  .box.height-control .box-body {
    max-height: 300px;
    overflow: auto;
  }
  .box .border-right {
    border-right: 1px solid #f4f4f4;
  }
  .box .border-left {
    border-left: 1px solid #f4f4f4;
  }
  .box.box-solid {
    border-top: 0;
  }
  .box.box-solid > .box-header .btn.btn-default {
    background: transparent;
  }
  .box.box-solid > .box-header .btn:hover,
  .box.box-solid > .box-header a:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .box.box-solid.box-default {
    border: 1px solid #d2d6de;
  }
  .box.box-solid.box-default > .box-header {
    color: #444;
    background: #d2d6de;
    background-color: #d2d6de;
  }
  .box.box-solid.box-default > .box-header a,
  .box.box-solid.box-default > .box-header .btn {
    color: #444;
  }
  .box.box-solid.box-primary {
    border: 1px solid #3c8dbc;
  }
  .box.box-solid.box-primary > .box-header {
    color: #fff;
    background: #3c8dbc;
    background-color: #3c8dbc;
  }
  .box.box-solid.box-primary > .box-header a,
  .box.box-solid.box-primary > .box-header .btn {
    color: #fff;
  }
  .box.box-solid.box-info {
    border: 1px solid #00c0ef;
  }
  .box.box-solid.box-info > .box-header {
    color: #fff;
    background: #00c0ef;
    background-color: #00c0ef;
  }
  .box.box-solid.box-info > .box-header a,
  .box.box-solid.box-info > .box-header .btn {
    color: #fff;
  }
  .box.box-solid.box-danger {
    border: 1px solid #dd4b39;
  }
  .box.box-solid.box-danger > .box-header {
    color: #fff;
    background: #dd4b39;
    background-color: #dd4b39;
  }
  .box.box-solid.box-danger > .box-header a,
  .box.box-solid.box-danger > .box-header .btn {
    color: #fff;
  }
  .box.box-solid.box-warning {
    border: 1px solid #f39c12;
  }
  .box.box-solid.box-warning > .box-header {
    color: #fff;
    background: #f39c12;
    background-color: #f39c12;
  }
  .box.box-solid.box-warning > .box-header a,
  .box.box-solid.box-warning > .box-header .btn {
    color: #fff;
  }
  .box.box-solid.box-success {
    border: 1px solid #00a65a;
  }
  .box.box-solid.box-success > .box-header {
    color: #fff;
    background: #00a65a;
    background-color: #00a65a;
  }
  .box.box-solid.box-success > .box-header a,
  .box.box-solid.box-success > .box-header .btn {
    color: #fff;
  }
  .box.box-solid > .box-header > .box-tools .btn {
    border: 0;
    box-shadow: none;
  }
  .box.box-solid[class*="bg"] > .box-header {
    color: #fff;
  }
  .box .box-group > .box {
    margin-bottom: 5px;
  }
  .box .knob-label {
    text-align: center;
    color: #333;
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 0.3em;
  }
  .box > .overlay,
  .overlay-wrapper > .overlay,
  .box > .loading-img,
  .overlay-wrapper > .loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .box .overlay,
  .overlay-wrapper .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
  }
  .box .overlay > .fa,
  .overlay-wrapper .overlay > .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: #000;
    font-size: 30px;
  }
  .box .overlay.dark,
  .overlay-wrapper .overlay.dark {
    background: rgba(0, 0, 0, 0.5);
  }
  .box-header:before,
  .box-body:before,
  .box-footer:before,
  .box-header:after,
  .box-body:after,
  .box-footer:after {
    content: " ";
    display: table;
  }
  .box-header:after,
  .box-body:after,
  .box-footer:after {
    clear: both;
  }
  .box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
  }
  .box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
  }
  .collapsed-box .box-header.with-border {
    border-bottom: none;
  }
  .box-header > .fa,
  .box-header > .glyphicon,
  .box-header > .ion,
  .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
  }
  .box-header > .fa,
  .box-header > .glyphicon,
  .box-header > .ion {
    margin-right: 5px;
  }
  .box-header > .box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .box-header > .box-tools [data-toggle="tooltip"] {
    position: relative;
  }
  .box-header > .box-tools.pull-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .box-header > .box-tools .dropdown-menu > li > a {
    color: #444 !important;
  }
  .btn-box-tool {
    padding: 5px;
    font-size: 12px;
    background: transparent;
    color: #97a0b3;
  }
  .open .btn-box-tool,
  .btn-box-tool:hover {
    color: #606c84;
  }
  .btn-box-tool.btn:active {
    box-shadow: none;
  }
  .box-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
  }
  .no-header .box-body {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .box-body > .table {
    margin-bottom: 0;
  }
  .box-body .fc {
    margin-top: 5px;
  }
  .box-body .full-width-chart {
    margin: -19px;
  }
  .box-body.no-padding .full-width-chart {
    margin: -9px;
  }
  .box-body .box-pane {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 3px;
  }
  .box-body .box-pane-right {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .box-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
  }
  
  .bg-red,
  .bg-yellow,
  .bg-aqua,
  .bg-blue,
  .bg-light-blue,
  .bg-green,
  .bg-navy,
  .bg-teal,
  .bg-olive,
  .bg-lime,
  .bg-orange,
  .bg-fuchsia,
  .bg-purple,
  .bg-maroon,
  .bg-black,
  .bg-red-active,
  .bg-yellow-active,
  .bg-aqua-active,
  .bg-blue-active,
  .bg-light-blue-active,
  .bg-green-active,
  .bg-navy-active,
  .bg-teal-active,
  .bg-olive-active,
  .bg-lime-active,
  .bg-orange-active,
  .bg-fuchsia-active,
  .bg-purple-active,
  .bg-maroon-active,
  .bg-black-active,
  .callout.callout-danger,
  .callout.callout-warning,
  .callout.callout-info,
  .callout.callout-success,
  .alert-success,
  .alert-danger,
  .alert-error,
  .alert-warning,
  .alert-info,
  .label-danger,
  .label-info,
  .label-warning,
  .label-primary,
  .label-success,
  .modal-primary .modal-body,
  .modal-primary .modal-header,
  .modal-primary .modal-footer,
  .modal-warning .modal-body,
  .modal-warning .modal-header,
  .modal-warning .modal-footer,
  .modal-info .modal-body,
  .modal-info .modal-header,
  .modal-info .modal-footer,
  .modal-success .modal-body,
  .modal-success .modal-header,
  .modal-success .modal-footer,
  .modal-danger .modal-body,
  .modal-danger .modal-header,
  .modal-danger .modal-footer {
    color: #fff !important;
  }
  .bg-gray {
    color: #000;
    background-color: #d2d6de !important;
  }
  .bg-gray-light {
    background-color: #f7f7f7;
  }
  .bg-black {
    background-color: #111 !important;
  }
  .bg-red,
  .callout.callout-danger,
  .alert-danger,
  .alert-error,
  .label-danger,
  .modal-danger .modal-body {
    background-color: #dd4b39 !important;
  }
  .bg-yellow,
  .callout.callout-warning,
  .alert-warning,
  .label-warning,
  .modal-warning .modal-body {
    background-color: #f39c12 !important;
  }
  .bg-aqua,
  .callout.callout-info,
  .alert-info,
  .label-info,
  .modal-info .modal-body {
    background-color: #00c0ef !important;
  }
  .bg-blue {
    background-color: #0073b7 !important;
  }
  .bg-light-blue,
  .label-primary,
  .modal-primary .modal-body {
    background-color: #3c8dbc !important;
  }
  .bg-green,
  .callout.callout-success,
  .alert-success,
  .label-success,
  .modal-success .modal-body {
    background-color: #00a65a !important;
  }
  .bg-navy {
    background-color: #001f3f !important;
  }
  .bg-teal {
    background-color: #39cccc !important;
  }
  .bg-olive {
    background-color: #3d9970 !important;
  }
  .bg-lime {
    background-color: #01ff70 !important;
  }
  .bg-orange {
    background-color: #ff851b !important;
  }
  .bg-fuchsia {
    background-color: #f012be !important;
  }
  .bg-purple {
    background-color: #605ca8 !important;
  }
  .bg-maroon {
    background-color: #d81b60 !important;
  }
  .bg-gray-active {
    color: #000;
    background-color: #b5bbc8 !important;
  }
  .bg-black-active {
    background-color: #000 !important;
  }
  .bg-red-active,
  .modal-danger .modal-header,
  .modal-danger .modal-footer {
    background-color: #d33724 !important;
  }
  .bg-yellow-active,
  .modal-warning .modal-header,
  .modal-warning .modal-footer {
    background-color: #db8b0b !important;
  }
  .bg-aqua-active,
  .modal-info .modal-header,
  .modal-info .modal-footer {
    background-color: #00a7d0 !important;
  }
  .bg-blue-active {
    background-color: #005384 !important;
  }
  .bg-light-blue-active,
  .modal-primary .modal-header,
  .modal-primary .modal-footer {
    background-color: #357ca5 !important;
  }
  .bg-green-active,
  .modal-success .modal-header,
  .modal-success .modal-footer {
    background-color: #008d4c !important;
  }
  .bg-navy-active {
    background-color: #001a35 !important;
  }
  .bg-teal-active {
    background-color: #30bbbb !important;
  }
  .bg-olive-active {
    background-color: #368763 !important;
  }
  .bg-lime-active {
    background-color: #00e765 !important;
  }
  .bg-orange-active {
    background-color: #ff7701 !important;
  }
  .bg-fuchsia-active {
    background-color: #db0ead !important;
  }
  .bg-purple-active {
    background-color: #555299 !important;
  }
  .bg-maroon-active {
    background-color: #ca195a !important;
  }
  [class^="bg-"].disabled {
    opacity: 0.65;
    filter: alpha(opacity=65);
  }
  
  
  .link-muted {
    color: #7a869d;
  }
  .link-muted:hover,
  .link-muted:focus {
    color: #606c84;
  }
  .link-black {
    color: #666;
  }
  .link-black:hover,
  .link-black:focus {
    color: #999;
  }
  .hide {
    display: none !important;
  }
  .no-border {
    border: 0 !important;
  }
  .no-padding {
    padding: 0 !important;
  }
  .no-margin {
    margin: 0 !important;
  }
  .no-shadow {
    box-shadow: none !important;
  }
  .list-unstyled,
  .chart-legend,
  .contacts-list,
  .users-list,
  .mailbox-attachments {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .list-group-unbordered > .list-group-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .flat {
    border-radius: 0 !important;
  }
  .text-bold,
  .text-bold.table td,
  .text-bold.table th {
    font-weight: 700;
  }
  .text-sm {
    font-size: 12px;
  }
  .jqstooltip {
    padding: 5px !important;
    width: auto !important;
    height: auto !important;
  }
  .bg-teal-gradient {
    background: #39cccc !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #39cccc),
      color-stop(1, #7adddd)
    ) !important;
    background: -ms-linear-gradient(bottom, #39cccc, #7adddd) !important;
    background: -moz-linear-gradient(
      center bottom,
      #39cccc 0,
      #7adddd 100%
    ) !important;
    background: -o-linear-gradient(#7adddd, #39cccc) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7adddd', endColorstr='#39cccc', GradientType=0) !important;
    color: #fff;
  }
  .bg-light-blue-gradient {
    background: #3c8dbc !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #3c8dbc),
      color-stop(1, #67a8ce)
    ) !important;
    background: -ms-linear-gradient(bottom, #3c8dbc, #67a8ce) !important;
    background: -moz-linear-gradient(
      center bottom,
      #3c8dbc 0,
      #67a8ce 100%
    ) !important;
    background: -o-linear-gradient(#67a8ce, #3c8dbc) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#67a8ce', endColorstr='#3c8dbc', GradientType=0) !important;
    color: #fff;
  }
  .bg-blue-gradient {
    background: #0073b7 !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #0073b7),
      color-stop(1, #0089db)
    ) !important;
    background: -ms-linear-gradient(bottom, #0073b7, #0089db) !important;
    background: -moz-linear-gradient(
      center bottom,
      #0073b7 0,
      #0089db 100%
    ) !important;
    background: -o-linear-gradient(#0089db, #0073b7) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0089db', endColorstr='#0073b7', GradientType=0) !important;
    color: #fff;
  }
  .bg-aqua-gradient {
    background: #00c0ef !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #00c0ef),
      color-stop(1, #14d1ff)
    ) !important;
    background: -ms-linear-gradient(bottom, #00c0ef, #14d1ff) !important;
    background: -moz-linear-gradient(
      center bottom,
      #00c0ef 0,
      #14d1ff 100%
    ) !important;
    background: -o-linear-gradient(#14d1ff, #00c0ef) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#14d1ff', endColorstr='#00c0ef', GradientType=0) !important;
    color: #fff;
  }
  .bg-yellow-gradient {
    background: #f39c12 !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #f39c12),
      color-stop(1, #f7bc60)
    ) !important;
    background: -ms-linear-gradient(bottom, #f39c12, #f7bc60) !important;
    background: -moz-linear-gradient(
      center bottom,
      #f39c12 0,
      #f7bc60 100%
    ) !important;
    background: -o-linear-gradient(#f7bc60, #f39c12) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7bc60', endColorstr='#f39c12', GradientType=0) !important;
    color: #fff;
  }
  .bg-purple-gradient {
    background: #605ca8 !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #605ca8),
      color-stop(1, #9491c4)
    ) !important;
    background: -ms-linear-gradient(bottom, #605ca8, #9491c4) !important;
    background: -moz-linear-gradient(
      center bottom,
      #605ca8 0,
      #9491c4 100%
    ) !important;
    background: -o-linear-gradient(#9491c4, #605ca8) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9491c4', endColorstr='#605ca8', GradientType=0) !important;
    color: #fff;
  }
  .bg-green-gradient {
    background: #00a65a !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #00a65a),
      color-stop(1, #00ca6d)
    ) !important;
    background: -ms-linear-gradient(bottom, #00a65a, #00ca6d) !important;
    background: -moz-linear-gradient(
      center bottom,
      #00a65a 0,
      #00ca6d 100%
    ) !important;
    background: -o-linear-gradient(#00ca6d, #00a65a) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ca6d', endColorstr='#00a65a', GradientType=0) !important;
    color: #fff;
  }
  .bg-red-gradient {
    background: #dd4b39 !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #dd4b39),
      color-stop(1, #e47365)
    ) !important;
    background: -ms-linear-gradient(bottom, #dd4b39, #e47365) !important;
    background: -moz-linear-gradient(
      center bottom,
      #dd4b39 0,
      #e47365 100%
    ) !important;
    background: -o-linear-gradient(#e47365, #dd4b39) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e47365', endColorstr='#dd4b39', GradientType=0) !important;
    color: #fff;
  }
  .bg-black-gradient {
    background: #111 !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #111),
      color-stop(1, #2b2b2b)
    ) !important;
    background: -ms-linear-gradient(bottom, #111, #2b2b2b) !important;
    background: -moz-linear-gradient(
      center bottom,
      #111 0,
      #2b2b2b 100%
    ) !important;
    background: -o-linear-gradient(#2b2b2b, #111) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b2b2b', endColorstr='#111111', GradientType=0) !important;
    color: #fff;
  }
  .bg-maroon-gradient {
    background: #d81b60 !important;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #d81b60),
      color-stop(1, #e73f7c)
    ) !important;
    background: -ms-linear-gradient(bottom, #d81b60, #e73f7c) !important;
    background: -moz-linear-gradient(
      center bottom,
      #d81b60 0,
      #e73f7c 100%
    ) !important;
    background: -o-linear-gradient(#e73f7c, #d81b60) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e73f7c', endColorstr='#d81b60', GradientType=0) !important;
    color: #fff;
  }
  