.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;

  &__main {
    min-height: 100vh;

    @at-root .app--private & {
      padding: ($header-height + $grid-gutter-width) 0 ($footer-height + $grid-gutter-width);
    }
  }

  &__container {
    @include app-container();
  }

  &__route {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__logout {
    span {
      margin-right: $grid-gutter-width / 6;
    }
  }
}

.is-fullwidth {
  width: 100% !important;
}

.is-fullheight {
  height: 100%;
}

.main-content {
  margin: 0.6rem;
}

.panel {
  background-image: linear-gradient(45deg, #0a906e 0%, #acdcbf 71%, #87a781 100%);
  padding: 0.5rem;
  color: white;
  border-radius: 4.3px;
  box-shadow: 3px 3px 1px 1px rgba(37, 34, 34, 0.1);
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.breadcrumb {
  background: whitesmoke;
  margin-bottom: 0;
}

.react-bs-table .table-bordered {
  font-size: 12px;
}

.text-center {
  text-align: center !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

#flash_notice {
  color: #f7f7f7;
  opacity: 0;
  border-radius: 2px;
  margin: 10px;
  padding: 20px;
  font-size: 18px;
  line-height: 18px;
}

#flash_notice.failure,
#flash_notice.success {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

#flash_notice.failure {
  background: #ff3b30;
}

#flash_notice.success {
  background: #4cd964;
}

#flash_notice i.fa {
  float: right;
  cursor: pointer;
  line-height: 18px;
}

.preloading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: black;
  z-index: 2;
  opacity: 0.5;
  justify-content: center;
  display: flex;
  align-items: center;

  .progress {
    width: 200px;
    height: 20px;
    background: #fff;
    border-radius: 5px;
  }
}

.custom-card {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  &.a {
    // min-height: 29.4rem;
    border-radius: 10px 0 0 10px;
  }

  &.b {
    margin: 0;
    padding: 0.7rem;
    min-height: 29.4rem;
    border-radius: 0 10px 10px 0;
  }

  &.c {
    border: unset;
    margin: 0;
    background: white;
  }
}

.ktp {
  background-image: url("#{$media-path}images/ktp.jpg") !important;
  height: 16.6rem;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 25.4rem;
}

.is-top-align {
  border-top: 1px solid rgba(247, 239, 239, 0.2);
}

.page-title {
  font-weight: bold;
  border-bottom: 2px solid #05fb70;
  margin-bottom: 15px;
  padding-bottom: 10px;
  background-color: linear-gradient(135deg, #05fb70 0%, #bb6dec 100%);
  margin-top: 0px;
}

.page-title:after {
  border-bottom: 2px solid #f5f8fa;
  width: 60%;
  display: block;
  position: absolute;
  content: "";
  padding-bottom: 10px;
}

footer .page-title:after {
  border-bottom: 2px solid #fff;
}

footer .page-title {
  color: #333;
}

.ktp-header {
  text-align: center;
  padding: 0.4rem;
  line-height: 0.9rem;
  font-size: 14px;
  line-height: 1;
}

.ktp-body {
  padding: 0.3rem;

  .ktp-content {
    .nik {
      font-size: 13px;
    }

    .detail {
      font-size: 10px;
    }

    .subdetail {
      width: 6rem;
    }
  }

  .ktp-foto {
    .img {
      width: 200px;
      height: 200px;
      background: red;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.small-padding {
  padding: 1px !important;
}

.tabs.is-boxed a[disabled] {
  cursor: not-allowed;
}

.app-loading {
  position: absolute;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
  display: flex;
  background: black;
  opacity: 0.4;
  z-index: 20;
  align-items: center;
}

.buttons {
  .control {
    margin-right: 4px;
  }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.c-p {
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.4;
  z-index: 40;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  background: black;
  opacity: 0.7;
  z-index: 10;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-line-spin-clockwise-fade,
.la-line-spin-clockwise-fade > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-line-spin-clockwise-fade {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-line-spin-clockwise-fade.la-dark {
  color: #333;
}

.la-line-spin-clockwise-fade > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-line-spin-clockwise-fade {
  width: 32px;
  height: 32px;
}

.la-line-spin-clockwise-fade > div {
  position: absolute;
  width: 2px;
  height: 10px;
  margin: 2px;
  margin-top: -5px;
  margin-left: -1px;
  border-radius: 0;
  -webkit-animation: line-spin-clockwise-fade 1s infinite ease-in-out;
  -moz-animation: line-spin-clockwise-fade 1s infinite ease-in-out;
  -o-animation: line-spin-clockwise-fade 1s infinite ease-in-out;
  animation: line-spin-clockwise-fade 1s infinite ease-in-out;
}

.la-line-spin-clockwise-fade > div:nth-child(1) {
  top: 15%;
  left: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.875s;
  -moz-animation-delay: -0.875s;
  -o-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.la-line-spin-clockwise-fade > div:nth-child(2) {
  top: 25.2512626585%;
  left: 74.7487373415%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation-delay: -0.75s;
  -moz-animation-delay: -0.75s;
  -o-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.la-line-spin-clockwise-fade > div:nth-child(3) {
  top: 50%;
  left: 85%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.625s;
  -moz-animation-delay: -0.625s;
  -o-animation-delay: -0.625s;
  animation-delay: -0.625s;
}

.la-line-spin-clockwise-fade > div:nth-child(4) {
  top: 74.7487373415%;
  left: 74.7487373415%;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.la-line-spin-clockwise-fade > div:nth-child(5) {
  top: 84.9999999974%;
  left: 50.0000000004%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.375s;
  -moz-animation-delay: -0.375s;
  -o-animation-delay: -0.375s;
  animation-delay: -0.375s;
}

.la-line-spin-clockwise-fade > div:nth-child(6) {
  top: 74.7487369862%;
  left: 25.2512627193%;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.la-line-spin-clockwise-fade > div:nth-child(7) {
  top: 49.9999806189%;
  left: 15.0000039834%;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.125s;
  -moz-animation-delay: -0.125s;
  -o-animation-delay: -0.125s;
  animation-delay: -0.125s;
}

.la-line-spin-clockwise-fade > div:nth-child(8) {
  top: 25.2506949798%;
  left: 25.2513989292%;
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  transform: rotate(315deg);
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.la-line-spin-clockwise-fade.la-sm {
  width: 16px;
  height: 16px;
}

.la-line-spin-clockwise-fade.la-sm > div {
  width: 1px;
  height: 4px;
  margin-top: -2px;
  margin-left: 0;
}

.la-line-spin-clockwise-fade.la-2x {
  width: 64px;
  height: 64px;
}

.la-line-spin-clockwise-fade.la-2x > div {
  width: 4px;
  height: 20px;
  margin-top: -10px;
  margin-left: -2px;
}

.la-line-spin-clockwise-fade.la-3x {
  width: 96px;
  height: 96px;
}

.la-line-spin-clockwise-fade.la-3x > div {
  width: 6px;
  height: 30px;
  margin-top: -15px;
  margin-left: -3px;
}

/*
 * Animation
 */
@-webkit-keyframes line-spin-clockwise-fade {
  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes line-spin-clockwise-fade {
  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes line-spin-clockwise-fade {
  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes line-spin-clockwise-fade {
  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.table.nowrap {
  font-size: 11px;

  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    line-height: 1.4;
  }
}

.card-custom {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-bottom: 10px;
}

.loading-error {
  display: flex;
  /* width: 30%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.card.error {
  width: 30%;
}

/* toaster */
.recipe-toaster {
  z-index: 200;
}

.fotopegawai.thumb {
  img {
    width: 150px;
  }
}
