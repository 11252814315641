// .pages {
//   background: white;
//   display: block;
//   margin: 0 auto;
//   margin-bottom: 0.5cm;
//   box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
//   transform: scale(0.9);
// }

// .box.print {
//   margin-bottom: 0px;
//   border-radius: 0px;
//   padding-right: 0px;
//   background: rgb(204, 204, 204);

//   .print-container {
//     height: calc(100vh - 16vh);
//     overflow-y: auto;
//     overflow-x: auto;
//     justify-content: center;
//     display: flex;
//   }

//   .print-header {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//   }
// }

// @media print {

//   .header {
//     border-bottom: 3px solid #000;
//     /* padding-bottom: 10px; */
//     line-height: 24.1px;
//   }

//   .kop {
//     font-size: 14pt;
//     color: #000;
//     font-weight: bold;

//     &.sub {
//       font-size: 12pt;
//     }

//     &.italic {
//       font-size: 11pt;
//       font-style: italic;
//       font-weight: unset;
//     }
//   }

//   .indentasi {
//     text-indent: 40px;
//     line-height: 1.2;
//     text-align: justify;
//   }

//   td,
//   th {
//     padding: 0;
//     text-align: left;
//     vertical-align: top;
//   }

//   .width-50 {
//     width: 50%;
//     text-align: center;
//   }

//   .border.thick.print {

//     td,
//     th {
//       padding: 0 !important;
//       text-align: left;
//       vertical-align: top;
//     }
//   }
// }
// .panel-scale-6 {
//   transform: scale(0.6);
// }
// .panel-scale-8 {
//   transform: scale(0.8);
// }
// .panel-scale-9 {
//   transform: scale(0.9);
// }

body.print #print_target {
  height: 100% !important;
}

.tabs.is-boxed {
  margin-bottom: 0;
}

.print-box {
  background-color: #cccccc;
  width: 100%;
}

.print #print_target {
  overflow: unset;
  background-color: unset;
}

.print .print-box {
  background-color: unset;
}

@media print {
  .print-box {
    background-color: unset;
    width: 100%;
  }

  #print_target {
    padding-top: unset !important;
  }
}

.print-box {
  padding: unset !important;
}

#print_target {
  width: 100%;
  height: calc(100vh - 16vh);
  overflow-y: auto;
  overflow-x: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 4rem;

  .pagez {
    background: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 2px 2px 2px 2px #ccc, 2px 2px #ccc, 2px 2px #ccc;
  }

  .margin {
    border: 1px dashed;
    height: 100%;
    border-color: rgba(0, 0, 0, 0.1);
  }



  @media print {
    .margin {
      border: unset;
    }

    .pagez {
      box-shadow: unset;
    }

    .page-breaks::after {
      // content: '\ Copyright ©2021 SIMADES VERSI 2.0.0';
      font-size: 10px;
    }
  }

  .logo-desa {
    width: 90px;
  }

  .img-logo {
    img {
      width: 90px;
      height: 114px;
    }
  }

  @media screen, print {
    .page-breaks {
      font-size: 12pt;
      box-sizing: border-box;
      page-break-after: always;
      font-family: "Arial Narrow", Arial, sans-serif;
      font-weight: normal;
      width: 210mm;
      height: 333mm;
      padding: 2cm;
      color: #000;
      position: relative;
    }

    .page-breaks.A4.portrait {
      width: 210mm;
      height: 296mm;
    }

    .page-breaks.A4.landscape {
      height: 210mm;
      width: 296mm;
    }

    .page-breaks.folio.portrait {
      width: 216mm;
      height: 330mm;
    }

    .page-breaks.folio.landscape {
      height: 216mm;
      width: 330mm;
    }

    /* legal page */
    .page-breaks.leggal.portrait {
      width: 216mm;
      height: 356mm;
    }

    .page-breaks.leggal.landscape {
      height: 216mm;
      width: 356mm;
    }

    /* letter page */
    .page-breaks.leter.portrait {
      width: 216mm;
      height: 11in;
    }

    .page-breaks.leter.landscape {
      height: 216mm;
      width: 11in;
    }

    .margin-narrow {
      padding: 1.27cm;
    }

    .margin-custom {
      padding-top: 0.5cm;
      padding-left: 2.5cm;
      padding-right: 2cm;
      padding-bottom: 2cm;
    }

    .margin-normal {
      padding: 2.54cm;
    }

    .margin-wide {
      padding-top: 2.54cm;
      padding-left: 5.08cm;
      padding-right: 5.08cm;
      padding-bottom: 2.54cm;
    }

    /* for margin */
    @page margin-normal {
      padding: 25.4mm;
    }

    @page margin-narow {
      padding: 12.7mm;
    }

    @page margin-wide {
      padding-top: 2.54cm;
      padding-left: 5.08cm;
      padding-right: 5.08cm;
      padding-bottom: 2.54cm;
    }

    @page margin-custom {
      padding-top: 0.5cm;
      padding-left: 2.5cm;
      padding-right: 2cm;
      padding-bottom: 2cm;
    }

    div.margin-normal {
      page: margin-normal;
    }

    div.margin-custom {
      page: margin-custom;
    }

    div.margin-narrow {
      page: margin-narow;
    }

    div.margin-wide {
      page: margin-wide;
    }

    .margin-left-30 {
      margin-left: 30px !important;
    }

    table {
      color: #000;
    }

    div > .font-small {
      line-height: 1;
      color: #a6a6a6;
      font-size: 10pt;
    }

    .margin {
      line-height: 1.25;
    }

    strong {
      color: #000;
    }

    .border-bottom-solid {
      border-bottom: 2px solid #000;
      margin-bottom: 2px;
    }

    .hr-bold {
      border: 2px solid #000;
      height: 4px;
    }

    .is-label {
      min-width: 20rem;
    }

    .is-koma {
      margin-left: 5px;
      margin-right: 5px;
    }

    .is-justify {
      text-align: justify;
    }

    .margin-both-20 {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .margin-right-20 {
      margin-right: 20px;
    }

    .margin-right-40 {
      margin-right: 40px;
    }

    .margin-left-40 {
      margin-left: 40px;
    }

    .margin-left-20 {
      margin-left: 20px;
    }

    ul.indent > li {
      margin-left: 20px;
      list-style-type: disc;
    }

    .is-upper-alpha {
      list-style-type: upper-alpha;
      margin-left: 20px;
    }

    .is-unset-numbering {
      list-style-type: none;
    }

    .is-margin-left-20-minus {
      margin-left: -20px;
    }

    .is-lower-alpha {
      list-style-type: lower-alpha;
      margin-left: 20px;
    }

    .is-decimal {
      list-style-type: decimal;
      margin-left: 20px;
    }

    .is-no-decimal {
      list-style-type: none;
      margin-left: 0px;
    }

    .is-70-persen {
      width: 70%;
    }

    .is-30-persen {
      width: 30%;
    }
  }

  @media print {
    .logo-desa {
      width: 90px;
      margin-bottom: 4.5px;
    }
  }
}
