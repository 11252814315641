.browsehappy {
  background: #ccc;
  color: #000;
  margin: 2rem 0;
  padding: 2rem 0;
}

html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  min-height: 100%;
}

img {
  height: auto;
  max-width: 100%;
}



.not-found {
  text-align: center;

  h1 {
    font-size: 12.8rem;
    margin: 0;

    @include sm {
      font-size: 25.6rem;
    }
  }
}

.preloader .content {
  height: 100%;
  border-radius: 3.7px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 2rem;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #0774ff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
