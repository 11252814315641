/*  */

/*******************************
         Site Overrides
*******************************/
.pagination-btn-separator {
  float: left;
  height: 24px;
  margin: 3px 1px;
}

.pagination-page-list {
  margin: 0px 6px;
  padding: 1px 2px;
  width: auto;
  height: auto;
  border-width: 1px;
  border-style: solid;
}

.pagination .pagination-num {
  margin: 0 2px;
  padding: 0.9px;
  width: 4em;
  height: auto;
  text-align: center;
  font-size: 14px;
  border: solid 1px rgba(0, 0, 0, 0.4);
  border-radius: 2.7px;
}

.margin3 {
  margin: 3px;
}

.field.a {
  text-transform: capitalize;
}

.field.a::before {
  content: "";
  width: 1px;
  height: 3rem;
  position: absolute;
  left: 0.3rem;
  border: 1px solid #00b4d5;
}

.dropdown .field.a::before {
  content: "";
  width: 1px;
  height: 3rem;
  position: absolute;
  left: -0.4rem;
  border: 1px solid #00b4d5;
}

.navbar-item.is-card {
  width: 11.5rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin: 3px;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-radius: 4px;
  background: white;
}

.navbar-item.is-card:hover {
  background: whitesmoke;
  cursor: pointer;
}

.navbar-item.is-card.has-dropdown {
  padding: 10px;
}

.level.a {
  margin-bottom: 5px;
}

a.l-btn-small[disabled] {
  cursor: not-allowed;

  opacity: 0.5;
}

.f14 {
  font-size: 13px;
}

table.table-bordered {
  margin-bottom: 0;
}

.navbar-item {
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.navbar-item.is-active {
  background: whitesmoke;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  -webkit-transform: translate3d(0, -3px, 0);
  transform: translate3d(0, -3px, 0);
}

.navbar-item.is-active::before {
  content: "";
  width: 100%;
  height: 0.3em;
  position: absolute;
  bottom: 0;
  background: #007bff;
  left: 0;
}

.navbar.filter {
  padding: 1rem;
  border: 1px solid rgba(34, 36, 38, 0.1);
  margin-bottom: 1rem;
}

.navbar-dropdown.is-active {
  display: block;
}

a.is-active {
  font-weight: bold;
  color: black;
}

.react-bootstrap-table {
  font-size: 13px;
  min-height: 0.01%;
  overflow-x: auto;
}
.is-disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 1px;
  background-color: whitesmoke;
  /* padding: 50%; */
  z-index: 10;
  /* margin-top: 10px; */
  padding-top: 20rem;
  opacity: 0.8;
}

.sortable {
  background-image: url("#{$media-path}images/sort_both.png") !important;
  background-repeat: no-repeat !important;
  background-position: center right !important;
}
.sortable.demo-sorting-desc {
  background-color: #c8e6c9 !important;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
  background-image: url("#{$media-path}images/sort_asc.png") !important;
  background-repeat: no-repeat !important;
  background-position: center right !important;
}
.sortable.demo-sorting-asc {
  background-color: #d0d5d0 !important;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
  background-image: url("#{$media-path}images/sort_desc.png") !important;
  background-repeat: no-repeat !important;
  background-position: center right !important;
}

/*******************************
             Site Overrides
    *******************************/
