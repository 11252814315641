// *** Node modules
@import "~sass-mediaqueries/media-queries";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
// @import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
// *** Variables
@import "variables/app";
@import "variables/bootstrap";
@import "vendor/bootstraps/index";
// @import "variables/typography";

// *** Utilities
@import "utilities/functions";
@import "utilities/mixins";
@import "utilities/grid";

// *** Vendor
@import "vendor/bootstrap";
@import "vendor/bulma";
@import "vendor/blueprint";
@import "vendor/froalacustom";

// *** Layout
@import "layout/root";
@import "layout/app";
// untuk component loading
@import "components/loader";

// *** Modules
// @import "modules/animations";
// @import "modules/buttons";
// @import "modules/icons";
// @import "modules/transitions";

// *** Components
// @import "components/alert";
// @import "components/footer";
// @import "components/header";

// @import "components/logo";
// @import "components/system-alerts";

// *** Routes
@import "routes/home";
@import "routes/loginquery";
// @import "routes/private";
@import "routes/not-found";
// @import "animate/animate.scss";
@import "containers/color.scss";

@import "loki/index.scss";
@import "print/index.scss";
@import "bulma-steps/index.sass";

@import "table/index.scss";
@import "table/custum";

// @import "tailwindcss/tailwind.css";

@import "font-awesome-4.7.0/scss/font-awesome";


// @import "table/tablesemantic.css"
