.app__not-found {
  align-items: center;
  background-color: $app-color;
  display: flex;
  justify-content: center;
  width: 100%;

  h1 {
    color: $white;
    font-size: 12rem;
    text-align: center;
  }

  a {
    color: darken($app-color, 20);
  }
}
