/* .btn-form::before {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    line-height: 25px;
    font-size: 1em;
    text-align: center;
    font-weight: 700;
    border-radius: 50px;
    z-index: 2;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.btn-form::before {
    position: absolute;
    counter-increment: section;
    content: counter(section);
    background: #ddd;
    color: #476688;
    margin-left: -20px;
    margin-top: 2.5px;
}

.bp3-collapse::before {
    content: "";
    display: block;
    position: absolute;
    left: -19px;
    top: -12px;
    background: #f0f0f0;
    width: 3px;
    height: 105%;
    z-index: 1;
} */
.is-validasi {
    /* display: flex; */
    /* float: right; */
    position: absolute;
    right: 0;
    margin-right: 20px;
}
.is_error {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 10;
    opacity: 0.8;
    padding-top: 40%;
    padding-left: 40%;
}
