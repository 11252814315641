.metismenu {
  font-size: 14px !important;
  height: 100%;
  background-color: #fff;
  &:after {
    box-shadow: unset;
    border: 1px solid rgba(0,0,0,0.1);
  }
}
.nav-bar {
  border-bottom: 1px solid rgba(174, 175, 208, 0.5);
  background-color: #007bff;
  min-height: 3.3rem;
  padding: 10px;
  .nav-custom-logo {
    width: 100%;
    .title {
      color: white;
      height: 100%;
      font-size: 1.7rem;
    }
  }
}

.subtitle.is-side {
  font-size: 16px;
  margin-bottom: 0;
  color: white;
  font-weight: 400;
}

.sidenav-active {
  width: 200px;
}

.no-border {
  border: unset;
}

.app__logo {
  display: inline-block;

  .isvg {
    display: block;
    line-height: 0;

    svg {
      height: auto;
      max-height: 100%;
    }
  }
}

