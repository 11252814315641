.editor-wrapper {
    box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.1), 1px 2px 3px rgba(0, 0, 0, 0.1);
    background: none;
}

.bf-content {
    height: 26.4rem;
}

form .bf-controlbar button {
    width: unset !important;
}

.fr-popup.fr-active {
    z-index: 200 !important;
}

.panel:not(:last-child) {
    margin-bottom: 0.7rem;
}