/* .gradient-45deg-purple-deep-orange {
    background: #8e24aa;
    background: -webkit-linear-gradient(45deg, #8e24aa 0%, #ff6e40 100%);
    background: linear-gradient(45deg, #8e24aa 0%, #ff6e40 100%);
} */

/*
 * less/style.less
 * Author: H.Alper Tuna <halpertuna@gmail.com>
 * Date: 21.08.2016
 */
/*
 * less/utils.less
 * Author: H.Alper Tuna <halpertuna@gmail.com>
 * Date: 21.08.2016
 */
 .metismenu {
    /* background: #303336; */
    font-family: "Open Sans", sans-serif;
    font-size: 12pt;
    overflow: hidden;
    position: relative;
  }
  .metismenu > .metismenu-container > .metismenu-item > .metismenu-link {
    line-height: 2.5em;
  }
  .metismenu
    > .metismenu-container
    > .metismenu-item
    > .metismenu-link
    .metismenu-state-icon {
    line-height: 2.5em;
  }
  .metismenu::after {
    box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
    -webkit-box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
    content: " ";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .metismenu-container,
  .metismenu-item {
    margin: 0;
    padding: 0;
  }
  .metismenu-container {
    list-style: none;
  }
  .metismenu-container .metismenu-container {
    transition: padding 300ms;
    -webkit-transition: padding 300ms;
    background: rgba(255, 255, 255, 0.05);
  }
  .metismenu-container .metismenu-container .metismenu-item > .metismenu-link {
    height: 0;
    overflow: hidden;
  }
  .metismenu-container .metismenu-container .metismenu-link {
    padding-left: 1em;
  }
  .metismenu-container .metismenu-container .metismenu-container .metismenu-link {
    padding-left: 2em;
  }
  
  .metismenu-container.visible > .metismenu-item > .metismenu-link {
    height: 2.5em;
  }
  .metismenu-link {
    color: #ddd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
    -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
    transition: height 300ms, color 300ms, background-color 300ms;
    -webkit-transition: height 300ms, color 300ms, background-color 300ms;
    display: block;
    line-height: 2.5em;
    text-decoration: none;
  }
  
  .metismenu-link {
    color: black;
    text-shadow: unset;
  }
  .metismenu-link:hover {
    background: #00d1b2;
    color: #f7f7f7;
    opacity: 0.5;
  }
  .metismenu-link.active {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background: #0a906e;
    color: #fff;
  }
  
  .metismenu-container.visible {
    padding: 0.5em 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
  }
  .metismenu-container .metismenu-container {
    transition: padding 300ms;
    -webkit-transition: padding 300ms;
    background: rgba(255, 255, 255, 0.05);
  }
  
  .metismenu-link.has-active-child {
    /* color: whitesmoke; */
    background: #0a906e;
    /* margin-right: 10px; */
    opacity: 0.8;
  }
  
  i.metismenu-icon {
    text-align: center;
    width: 3em;
  }
  i.metismenu-state-icon {
    transition: transform 300ms;
    -webkit-transition: transform 300ms;
    float: right;
    line-height: 2.5em;
    text-align: center;
    width: 3em;
  }
  i.metismenu-state-icon.rotate-minus-90 {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }
  