.h {
    border: 1px dashed;
}

.m-list {
    font-size: 1.2rem;
    text-align: center;
}

.hidden::before {
    content: '';
    position: absolute;
    background-color: white;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.is-custom-p {
    height: 27rem;
    overflow: auto;
}

.menu.hero-list.a {
    min-height: 350px !important;
}

.col-1a {
    border: 1px dashed;
    padding: 10px;
    cursor: pointer;
}

.col-1a:hover {
    background: green;
    color: white;
}

.col-1a.is-active {
    background: green;
    color: white;
}

.breadcrumbs li+li::before {
    color: #b5b5b5;
    content: "\0002f";
}

.is-blog {
    width: 100%;
    margin-left: 10px;
    height: 10rem;
    justify-content: center;
    display: flex;
    margin-top: 5px;
}

.m-list.a {
    text-align: left;
}

.is-pointer {
    cursor: pointer;
}

.bp3-menu-item[disabled] {
    cursor: not-allowed;
    color: whitesmoke;
}