$loader-size: 2rem;
$loader-speed: 1s;
$width: 10rem;

.app__loader {
  &--rotate {
    height: $width;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $width;

    &__svg {
      animation: rotate 2s linear infinite;
      height: $width;
      position: relative;
      width: $width;
    }

    &__circle {
      animation: dash 1.5s ease-in-out infinite;
      stroke: #333;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }
  }

  &--pulse {
    height: $loader-size;
    margin: ($grid-gutter-width / 2) auto;
    position: relative;
    width: $loader-size;

    > div {
      animation: loader-pulse 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
      border: 0 solid $app-color;
      border-radius: 50%;
      box-sizing: border-box;
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
    }
  }
}

@keyframes loader-pulse {
  0% {
    height: 0;
    width: 0;
  }

  30% {
    border-width: 0.8rem;
    height: $loader-size;
    opacity: 1;
    width: $loader-size;
  }

  100% {
    border-width: 0;
    height: $loader-size;
    opacity: 0;
    width: $loader-size;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

.preload {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
