/* .tabs {
    margin-bottom: -1px !important;
}
 */
.tab-content {
    border: 1px solid #DBDBDB;
    padding: 10px;
}


.tab-pane {
    display: none;
}

.is-active {
    display: initial;
}

.ui.table td.px {
    padding: 2px;
    text-align: center;
}

/* Reset */