/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.formlogin {
    padding: 20px 10px;
    position: fixed;
    z-index: 2;
    margin-top: 2rem;
    margin-left: 10rem;
    background: #3131;
    box-shadow: 0 0 10px #000000,
        0 0 0 1px rgba(47, 47, 47, 0.5) inset;
    border-radius: 5px;
    max-width: 270px;
}

.title-login {
    font-size: 2rem;
    // text-align: uppercase;
    font-family: fantasy;
    color: white;
    margin-left: 120px;
}

.panel-login {
    max-width: 30.4%;
}
.avatar {
    width: 100%;
}


@media (min-width: 1281px) {

    //CSS

}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    //CSS

}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

    //CSS

}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    //CSS

}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

    //CSS

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

    //CSS
    .formlogin {
        margin-left: 4.3rem;
    }

    .title-login {
        // text-align: uppercase;
        color: white;
        margin-left: 10px;
    }
    .panel-login {
        max-width: 100%;
    }
    .avatar-panel {
        display: none;
    }
    .formLoginBack {
        display: none;
    }
    .aviso {
        display: block;
    }
}