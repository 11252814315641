.pencarian {
    display: inline-block;
    width: 60%;
}
@media only screen and (max-device-width: 640px) {
    /* Styles */
    .pencarian {
        width: calc(100% - 12%);
        position: absolute;
    }
    .pencarian .with-select {
        display: none;
    }
    .pencarian input {
        border-radius: 10px;
    }
}

.select-parent {
    margin-top: 5px;
    display: flex;
    border-radius: 1em;
    background-color: whitesmoke;
}
.pencarian input {
    height: 40px;
    /* background-color: rgba(255, 255, 255, 0.5); */
}
.pencarian input::hover {
    color: #333;
}

.with-category {
    padding-right: 150px;
}
.with-category:hover {
    background-color: whitesmoke;
}

.with-select {
    display: flex;
    align-items: center;
    color: black;
    padding-left: 20px;
}

.with-select select {
    background: transparent;
    border-style: none;
}

.downvirtual {
    width: 100%;
    font-size: 14px;
    word-wrap: break-word;
    line-height: 1em;
    outline: 0;
    white-space: normal;
    min-height: 2em;
    background: #fff;
    display: inline-block;
    padding: 1em 2em 1em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.3rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
}

input.downvirtual[disabled] {
    cursor: not-allowed;
    background-color: #c6e8e3;
}

.items {
    margin: 10px;
}
