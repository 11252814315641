br {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

.treeview>.toggle {
    pointer-events: all;
    cursor: pointer;
}

.treeview>.toggle:hover {
    color: #63b1de;
    fill: #63b1de;
}
