header.navbar {
  height: 40px;
  margin: 5px 20px;
}

div.menu .menu-item.tile.tile-centered {
  margin-top: 5px;
}

.divider[data-content] {
  margin: .8rem 0;
}

.divider {
  border-top: .05rem solid #f1f3f5;
  height: .05rem;
  margin: .4rem 0;
}

.divider,
.divider-vert {
  display: block;
  position: relative;
}

.menu.hero-list {
  z-index: inherit;
  transform: none;
  min-height: 450px;
}

.menu {
  border-radius: .1rem;
  box-shadow: 0 0.05rem 0.2rem rgba(48, 55, 66, .3);
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: .4rem;
  transform: translateY(.2rem);
  z-index: 300;
}

.menu-item {
  border: 1px dashed rgba(0, 0, 0, 0.1);
  min-width: 2rem;
  /* max-width: 11rem; */
  color: white;
  font-weight: bold;
  position: relative;
}


.multiline-strikethrough {
  width: 100%;
  height: 2.5px;
  position: absolute;
  background: black;
  z-index: 2;
  top: 50%;
  border: 1px dashed;
  cursor: not-allowed;
}

.is-centered {
  align-items: center;
}

.overflow-aut {
  height: 34rem;
  overflow: auto;
}

.is-margin-10 {
  margin: 10px;
}

.divider-vert[data-content]:after,
.divider[data-content]:after {
  background: #fff;
  color: #bcc3ce;
  content: attr(data-content);
  display: inline-block;
  font-size: .7rem;
  padding: 0 .4rem;
  transform: translateY(-.65rem);
}

.move-item {
  /* cursor: move; */
  background-color: burlywood;
  height: 100%;
  width: 3rem;
  position: relative;
  /* top: -2.7px; */
}

/* .move-item.disabled {} */

.move-item-a {
  padding: 5px;
}

.menu-item.has-dropdown {
  width: 100%;
  max-width: unset;
}

.menu-item.is-child {
  height: 3rem;
}